// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPage_v6OHB .rating_puoO4{padding-top:0}@media(max-width:767.98px){.MainPage_v6OHB .rating_puoO4{padding-top:4rem}}.blocks_olMzX{overflow:hidden}.blocks_olMzX:not(:last-child){margin-bottom:9rem}@media(max-width:767.98px){.blocks_olMzX:not(:last-child){margin-bottom:6rem}}.footer_FkEDm{display:none}@media(max-width:767.98px){.footer_FkEDm{background:#fff;bottom:0;display:block;opacity:0;pointer-events:none;position:-webkit-sticky;position:sticky;transition:opacity .3s ease;z-index:10}.footer_FkEDm._show_QlwUJ{opacity:1;pointer-events:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"MainPage": "MainPage_v6OHB",
	"rating": "rating_puoO4",
	"blocks": "blocks_olMzX",
	"footer": "footer_FkEDm",
	"_show": "_show_QlwUJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
